/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: calc(10px + 3.5vmin);
  height: calc(8px + 2.5vmin);
  left: 36px;
  top: 36px;
}


/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #4a4a4a;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #264d1c;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #f7f6f7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #264d1c;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #f7f6f7;
  /* padding: 0.8em; */
  text-transform: uppercase;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

.bm-menu a {
  font-size: 40px;
  color: #f7f6f7;
  text-decoration: none;
}

.bm-menu a:hover {
  color: #9e9e9e;
  font-weight: 550;
}

.bm-menu a:active {
  color: lightblue;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}


@media (max-width : 600px) {
  .bm-burger-button {
    width: calc(16px + 3.5vmin);
    height: calc(10px + 2.5vmin);;
  }
}