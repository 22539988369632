.venuecontainer{
    width: 60%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.venue-map-container{
    width: 70%;
    height: 400px;
    margin-left: auto;
    margin-right:auto;
    text-align: center;
    border: 1px solid black;
}


@media (max-width : 600px) {
    .venuecontainer{
        width: 90%;
    }
    
    .venue-map-container{
        width: 100%;
        height: 300px;
    }
    
}