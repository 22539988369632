.rsvp-form {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    text-align: center;
    padding: 10px;
}

.code-results-table{
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse; 
    table-layout: fixed;
    width: 100%;
}


.rsvp-form-guest-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.rsvp-form-guest-container::after {
    margin-left: auto;
    margin-right: auto;
    content: '';
    display: block;
    height: 15px;
    border-bottom: 3px solid #264d1c;
    width: 65%;
}

hr {
    background-color: #264d1c;
    height: 3px;
    width: 80%;
}

input[name="form_invite_code"]{
    width: 30%;
}

.rsvp-form-key-title {
    font-weight: bold;
    font-size: calc(14px + 1vmin) ;
    padding-top: 20px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
}

.rsvp-form-key {
    text-align: right;
    padding-right: 5px;
    display: inline-block;
    width: 20%;
    padding-bottom: 5px;
}

.rsvp-form-value {
    text-align: center;
    padding-left: 5px;
    display: inline-block;
    width: 20%;
}


select {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }

input {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }


.form-submit-message-wait {
    font-weight: bold;
    margin-top: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.form-submit-message-success {
    font-weight: bold;
    margin-top: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    animation: message-success-background 2s linear;
    animation-fill-mode: forwards;
}

@keyframes message-success-background {
    0%  {
        background-color: transparent;
    }
    10%,50% {
        background-color: #70b75f;
    }
    60% {
        background-color: transparent;
    }
    70% {
        background-color: #70b75f;
    }
    80% {
        background-color: transparent;
    }
    90% {
        background-color: #70b75f;
    }
    100% {
        background-color: transparent;
    }
}


.form-submit-message-failure {
    font-weight: bold;
    margin-top: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    animation: message-failure-background 2s linear;
    animation-fill-mode: forwards;
}

@keyframes message-failure-background {
    0%  {
        background-color: transparent;
    }
    10%,50% {
        background-color: #df3434;
    }
    60% {
        background-color: transparent;
    }
    70% {
        background-color: #df3434;
    }
    80% {
        background-color: transparent;
    }
    90%,100% {
        background-color: #df3434;
    }
}


.form-submit-message-missing-fields {
    font-weight: bold;
    margin-top: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    animation: message-missing-fields-background 2s linear;
    animation-fill-mode: forwards;
}

@keyframes message-missing-fields-background {
    0%  {
        background-color: transparent;
    }
    10%,50% {
        background-color: #ffcc33;
    }
    60% {
        background-color: transparent;
    }
    70% {
        background-color: #ffcc33;
    }
    80% {
        background-color: transparent;
    }
    90%,100% {
        background-color: #ffcc33;
    }
}

@media (max-width : 600px) {
    input[name="form_invite_code"]{
        width: 90%;
    }
    .rsvp-form {
        width: 90%;
    }
    .rsvp-form-key-title {
        font-size: calc(18px + 1vmin) ;
    }
    .rsvp-form-key {
        width: 100%;
        text-align: center;
        font-weight: bold;
    }
    .rsvp-form-value {
        width: 70%;
        padding-bottom: 20px;
    }
    rsvp-form-guest-container::after {
        width: 100%;
    }
}