html, body, #root {
  height: 99%;
}
#root {
  display: flex;
  flex-direction: column;
 }


body {
  margin: 0px;
  font-family: 'DM Mono', monospace;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f6f7;
}

.bodytext {
  text-align: center;
  font-size: calc(10px + 1vmin) ;
  color: #4a4a4a;
  margin-bottom: 5%;
}

.bodytext a{
  color: #4a4a4a;
}

.bodytext a:visited{
  color: #4a4a4a;
}

/* TODO: change font size for device, too small even with calc */
.footer {
  margin-top: auto;
  font-size: calc(12px + 0.1vmin);
  left: 0;
  width: 100%;
  color: #737373;
  text-align: center;
}

.pagetitle {
  color: #264d1c;
  font-size: calc(40px + 1vmin);
  font-style: normal;
  font-family: "Cinzel", serif;
  font-weight: 600;
  padding-bottom: calc(10px + 1vmin);
  padding-top: calc(50px + 5vmin);
  margin: 0% 10% 5% 10%;
  text-align: center;
  text-transform: uppercase;
}

.subtitle-green {
  color: #264d1c;
  font-size: calc(35px + 1vmin);
  font-style: normal;
  font-family: "Cinzel", serif;
  font-weight: 600;
  padding-bottom: calc(10px + 1vmin);
  text-align: center;
  text-transform: uppercase; 
}

.subtitle-orange{
  color: #be5103;
  text-transform: uppercase;
  font-family: "Cinzel", serif;
  font-size: calc(30px + 1vmin);
  font-style: normal;
  font-weight: 600;
  padding-bottom: calc(5px + 1vmin);
  width: 100%;
}