.order-day-table {
 table-layout: fixed;
 width: 60%;   
 margin-left: auto;
 margin-right: auto;
}

.order-day-table td:first-child {
    text-align: right;
    width: 20%;
}

.order-day-table td {
    text-align: left;
    padding-left: 100px;
    width: 80%;
    padding-bottom: 10px;
}

@media (max-width : 600px) {
    .order-day-table {
        width: 95%;
    }
    .order-day-table td:first-child {
        text-align: left;
        width: 20%;
    }
    .order-day-table td {
        padding-left: 10px;
        padding-bottom: 20px;
    }
}