.HeaderTitle {
  padding-top: calc(50px + 5vmin);
  margin: 0% 10% 5% 10%;
  text-align: center;
}

.HeaderTitleText {
  color: #264d1c;
  font-style: normal;
  font-weight: 400;
  padding-bottom: calc(10px + 1vmin);
}

.HeaderTitleText .upper{
  font-size: calc(40px + 1vmin); 
  font-family: "Cinzel", serif;
  text-transform: uppercase;
}

.HeaderTitleText .lower{
  font-size: calc(20px + 1vmin); 
  font-family: 'La Belle Aurore', cursive;
  text-transform: lowercase;
}

.HeaderImage{
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;
}

.HeaderImage img {
  max-width: calc(50% + 2vmin);
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  border: 5px solid #264d1c;
  outline: 5px solid #be5103;
  outline-offset: 3px;
}

