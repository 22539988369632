.accordion {
    font-size: calc(10px + 1vmin) ;
    color: #4a4a4a;
    margin-bottom: 5%;
}

.accordian-title {
    width: 100%;
}

.accordion-title h1 {
    color: #be5103;
    font-family: "Afacad Flux", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: calc(25px + 1vmin) ;
    padding-left: 20px;
}


.accordion-content {
    font-family: "Afacad Flux", sans-serif;
    font-size: calc(15px + 1vmin) ;
    padding-bottom: 40px;
    padding-left: 20px;
}

.accordion-content a {
    color: #4a4a4a;
}

.faqtable {
    border: 1px solid #264d1c;
    border-radius: 20px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    table-layout: fixed;
}

.accordion-title-btn h1{
    color: #be5103;
    text-align: center;
}

@media (max-width : 600px) {
    .accordion-title h1 {
        font-size: calc(18px + 1vmin) ;
    }

    .faqtable {
        width: 90%;
    }
    
}