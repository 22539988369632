.travelcontainer{
    width: 60%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.travel-title{
    font-weight: 600;
    font-size: calc(14px + 1vmin) ;
    color: #be5103;
    padding-bottom: 20px;
}

.travel-title a{
    color: #be5103;
}


.travel-title a:visited{
    color: #be5103;
}